import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";
import { updateCampaignFinished } from "../../../api/api";

export default function Outro({ campaign, anonymousToken }) {
    const campaignLinkTarget = "_self";

    const { token } = useParams();
    const { email } = useParams();

    useQuery(["finish-campaign"], () => updateCampaignFinished({ token: token, email: email, anonymous_token: anonymousToken }), {
        retry: 1,
        onSuccess: (data) => {
            console.log("finish success", data);
        },
        onError: (error) => {
            console.log("error", error);
        },
    });

    useEffect(() => {
        setTimeout(function () {
            if (campaign?.link) {
                window.open(campaign.link, campaignLinkTarget);
            } else {
                window.location.href = "/";
            }
        }, 5000);
    });

    return (
        <div>
            <div className="content">
                <h1>Bedankt voor het meedoen!</h1>
                <p>We vinden het fijn dat je de moeite neemt om je mening met ons te delen.</p>

                <p>
                    Over 5 seconden word je automatisch doorgestuurd. Gebeurt dit niet?{" "}
                    {campaign.link ? (
                        <a target={campaignLinkTarget} href={campaign.link} rel="noreferrer">
                            Klik dan hier
                        </a>
                    ) : (
                        <Link to={"/"}>Klik dan hier</Link>
                    )}
                </p>
            </div>
            <div className="buttons_container">
                <Link to={"/"} className="btn btn-primary kingkong">
                    <span>Onderzoek afsluiten</span>
                    <img src={arrow_right_white} alt="arrow" />
                </Link>
            </div>
        </div>
    );
}
