import { Field, Formik, yupToFormErrors } from "formik";
import React, { useState } from "react";
import Select from "../../../components/Select";
import spinner_white from "../../../assets/images/icons/spinner_white.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { validateProfile } from "../../../utils/validation";
import AttributeFormGroup from "../../auth/register/parts/AttributeFormGroup";
import NoChildrenPopup from "../../components/NoChildrenPopUp";
import { useMutation } from "react-query";
import { resendEmailVerification, resendPhoneVerification } from "../../../api/api";
import { Notify } from "../../../utils/notify";

export default function Form({ data, selectedView, onSubmit }) {
    if (data && data.data) {
        data = data.data;
    }

    const [popupOpen, setPopupOpen] = useState(false);

    const resendEmailVerificationMutation = useMutation(() => resendEmailVerification());
    const resendPhoneVerificationMutation = useMutation(() => resendPhoneVerification());

    const onSave = async (values, setSubmitting, setErrors) => {
        try {
            setSubmitting(true);
            await validateProfile(
                values,
                data.attributes.map((a) => a.key)
            );
            onSubmit(values, setSubmitting, setErrors);
        } catch (ex) {
            setErrors(yupToFormErrors(ex));
            return;
        } finally {
            setSubmitting(false);
        }
    };

    const saveButton = (values, isSubmitting, setSubmitting, setErrors) => (
        <button type="button" className="btn btn-primary kingkong" onClick={() => (!isSubmitting ? onSave(values, setSubmitting, setErrors) : null)}>
            {isSubmitting ? (
                <div className="spinner">
                    <img src={spinner_white} alt="spinner" />
                </div>
            ) : (
                <>
                    <span>Opslaan</span>
                </>
            )}
        </button>
    );

    const updatedChildrenAmountInputs = (option, values, setFieldValue) => {
        if (option.label === "0") {
            setPopupOpen(true);
            return;
        }

        var dobChildren = values["attributes"]["dob_children"];
        if (dobChildren === undefined) return;

        const amount = option.label === "Meer dan 5" ? 6 : parseInt(option.label);
        const diff = amount - dobChildren.length;
        if (diff > 0) {
            for (let i = 0; i < diff; i++) {
                dobChildren.push("");
            }
        } else if (diff < 0) {
            dobChildren = dobChildren.slice(0, option.label);
        }

        setFieldValue("attributes[dob_children]", dobChildren);
    };

    const resendEmailVerificationEmail = () => {
        resendEmailVerificationMutation.mutate([], {
            onSuccess: () => {
                Notify.success("Verificatie is opnieuw verstuurd!");
            },
            onError: () => {
                Notify.error("Het opnieuw versturen is mislukt, probeer het later nogmaals.");
            },
        });
    };

    const resendPhoneVerificationSms = () => {
        resendPhoneVerificationMutation.mutate([], {
            onSuccess: () => {
                Notify.success("Verificatie is opnieuw verstuurd!");
            },
            onError: () => {
                Notify.error("Het opnieuw versturen is mislukt, probeer het later nogmaals.");
            },
        });
    };

    return (
        <div className="register_form">
            <Formik
                initialValues={{
                    name: data?.name ?? "",
                    email: data?.email ?? "",
                    invitation_method: data?.invitation_method ?? "",
                    phone: data?.phone ?? "",
                    attributes: Object.values(data?.attributes ?? {})
                        .map((attribute) => {
                            if (attribute.key === "dob_children") {
                                return { [attribute.key]: attribute.value?.split("|") ?? [""] };
                            } else if (attribute.type === "checkbox") {
                                return { [attribute.key]: attribute.value === "Ja" };
                            } else {
                                return { [attribute.key]: attribute.value ?? "" };
                            }
                        })
                        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values, errors, setErrors, setFieldValue, handleSubmit, isSubmitting, setSubmitting, initialValues }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="profile_form_tabs">
                            <div className={"tab general " + (selectedView === "general" ? "selected" : "")}>
                                <div className="form-group">
                                    <label htmlFor="name">Voornaam</label>
                                    <Field name="name" type="text" className="form-control" />
                                    {errors?.name && <div className="error">{errors.name}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">E-mailadres *</label>
                                    <Field name="email" type="email" className="form-control" />
                                    {data &&
                                        (data.email_verified ? (
                                            <p className="hint">Je e-mailadres is geverifieerd.</p>
                                        ) : (
                                            <p className="hint">
                                                Je e-mailadres is nog niet geverifieerd. Als je de verificatie-e-mail niet hebt ontvangen, klik dan{" "}
                                                <a href="#" onClick={() => resendEmailVerificationEmail()}>
                                                    hier
                                                </a>{" "}
                                                om een nieuwe e-mail te versturen.
                                            </p>
                                        ))}
                                    {errors?.email && <div className="error">{errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Hoe wil je uitnodigingen voor onderzoek ontvangen?</label>
                                    <Select
                                        name="invitation_method"
                                        value={{
                                            value: values.invitation_method,
                                            label: values.invitation_method === "whatsapp" ? "WhatsApp" : "E-mail",
                                        }}
                                        options={[
                                            { value: "whatsapp", label: "WhatsApp" },
                                            { value: "email", label: "E-mail" },
                                        ]}
                                        onChange={(option) => setFieldValue("invitation_method", option.value)}
                                    />
                                    {errors?.invitation_method && <div className="error">{errors.invitation_method}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Telefoonnummer {values.invitation_method === "whatsapp" ? "*" : ""}</label>
                                    <PhoneInput
                                        defaultCountry="NL"
                                        name="phone"
                                        className="form-control"
                                        value={values.phone}
                                        onChange={(value) => setFieldValue("phone", value ?? "")}
                                    />
                                    {data &&
                                        data.phone !== null &&
                                        (data.phone_verified ? (
                                            <p className="hint">Je telefoonnummer is geverifieerd.</p>
                                        ) : (
                                            <p className="hint">
                                                Je telefoonnummer is nog niet geverifieerd. Als je de verificatie-sms niet hebt ontvangen, klik dan{" "}
                                                <a href="#" onClick={() => resendPhoneVerificationSms()}>
                                                    hier
                                                </a>{" "}
                                                om een nieuwe sms te versturen.
                                            </p>
                                        ))}
                                    {errors?.phone && <div className="error">{errors.phone}</div>}
                                </div>
                            </div>

                            <div className={"tab attributes " + (selectedView === "attributes" ? "selected" : "")}>
                                {Object.entries(values.attributes).map(([key, value], index) => {
                                    const attribute = data?.attributes.find((a) => a.key === key);

                                    return (
                                        <AttributeFormGroup
                                            key={index}
                                            sleutel={index}
                                            attribute={attribute}
                                            setFieldValue={setFieldValue}
                                            value={value}
                                            errors={errors}
                                            onChangeCallback={(attribute, option) => updatedChildrenAmountInputs(option, values, setFieldValue)}
                                        />
                                    );
                                })}
                            </div>

                            <div className="buttons_container">{saveButton(values, isSubmitting, setSubmitting, setErrors)}</div>
                        </div>
                    </form>
                )}
            </Formik>

            <NoChildrenPopup open={popupOpen} setOpen={setPopupOpen} />
        </div>
    );
}
