import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchCampagin } from "../../api/api";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Intro from "./parts/Intro";
import Outro from "./parts/Outro";
import Inactive from "./parts/Inactive";
import Question from "./parts/Question";
import constants from "../../utils/constants";
import MissingSegments from "./parts/MissingSegments";

export default function Campaign(params) {
    const { token } = useParams();
    const { email } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [current, setCurrent] = useState(0);
    const [parts, setParts] = useState([]);
    // eslint-disable-next-line
    const [history, setHistory] = useState([]);

    // anonymous token
    const [searchParams, setSearchParams] = useSearchParams();
    const [anonymousToken, setAnonymousToken] = useState(searchParams.get("a_t") ?? "");

    function handleStepperPrevious() {
        setHistory((prevHistory) => {
            // Stepper movement to previous step
            // Remove last element from history
            let tempHistory = [...prevHistory];
            tempHistory.pop();

            let prevHistoryPage = tempHistory[tempHistory.length - 1] ?? 0;

            if (prevHistoryPage < 0) {
                // If the last step is less than 0, it means that we are at the first step
                // So we should not go back
                return;
            }

            setCurrent(prevHistoryPage);
            return tempHistory;
        });
    }

    function handleStepperNext(goToPageNumber = 1) {
        setHistory((prevHistory) => {
            let tempHistory = [...prevHistory, goToPageNumber];
            setCurrent(goToPageNumber);
            return tempHistory;
        });
    }

    useQuery(["campaign", token], () => fetchCampagin({ token: token, email: email, anonymous_token: anonymousToken }), {
        retry: 1,
        onSuccess: (data) => {
            if (data.data) {
                console.log("data.data");
                data = data.data;
            }

            if (data && typeof data === "object") {
                if ("anonymous_token" in data) {
                    setSearchParams({ a_t: data.anonymous_token });

                    if (data.anonymous_token !== anonymousToken) {
                        setAnonymousToken(data.anonymous_token);
                    }
                }

                setParts([
                    <Intro campaign={data} nextStep={handleStepperNext} />,
                    ...data?.questions.map((question, index) => (
                        // include intro to number (+1)
                        <Question
                            question={question}
                            prevStep={handleStepperPrevious}
                            nextStep={handleStepperNext}
                            number={index + 1}
                            anonymousToken={anonymousToken}
                        />
                    )),
                    // If data.missing_segments is empty and not anonymous link
                    // Show update page with data.missing_segments elements as inputs
                    ...(data?.missing_segments?.length > 0 && params.anonymous !== true
                        ? [
                              <MissingSegments
                                  missingSegments={data.missing_segments}
                                  prevStep={handleStepperPrevious}
                                  nextStep={handleStepperNext}
                                  // include intro to number (+1)
                                  number={data?.questions.length + 1}
                              />,
                          ]
                        : []),
                    <Outro campaign={data} anonymousToken={anonymousToken} />,
                ]);
            } else {
                console.log("Error in data!");
                setParts([<Error message={`Er is iets misgegaan, ${constants.CONTACT_OO}`} />]);
            }

            setLoading(false);
        },
        onError: (error) => {
            if (error.response.data.type === "CAMPAIGN_INACTIVE") {
                setParts([<Inactive />]);
            } else {
                console.log(error);
                setParts([<Error message={`Er is iets misgegaan, ${constants.CONTACT_OO}`} />]);
            }
            setLoading(false);
        },
    });

    return (
        <section className="campaign">
            <div className="container_sm">{isLoading ? <Loading /> : parts[current] ?? <></>}</div>
        </section>
    );
}
